import React from "react";
import { ButtonStyled } from "./Button.styled";
const Button = ({ text, appearance = "primary", size, onClick }) => {
  return (
    <ButtonStyled appearance={appearance} buttonSize={size} onClick={onClick}>
      {text}
    </ButtonStyled>
  );
};

export default Button;
