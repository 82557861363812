import styled, { css } from "styled-components";

const APPEARANCES = {
  primary: {
    color: "#FF914D", //#18794E
    backgroundColor: "#FFE4D1", //#ddf3e4
    backgroundHoverColor: "#FFD6BB", //ccebd7
    backgroundActiveColor: "#FFE4D1", //#ddf3e4
    shadowColor: "#FFB088", // #92ceac
  },
  secondary: {
    color: "#FF914D", //#18794E
    backgroundColor: "#FFE4D1", //#ddf3e4
    backgroundHoverColor: "#FFD6BB", //ccebd7
    backgroundActiveColor: "#FFE4D1", //#ddf3e4
    shadowColor: "#FFB088", // #92ceac
  },
  tertiary: {
    color: "",
    backgroundColor: "",
    backgroundHoverColor: "",
    shadowColor: "",
  },
};
export const ButtonStyled = styled.button`
  all: unset;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  /* height: 35px; */

  font-size: ${({ buttonSize }) => (buttonSize === "big" ? "20px" : "16px")};
  padding: ${({ buttonSize }) =>
    buttonSize === "big" ? "15px 20px" : "10px 15px"};

  transition: all 0.5s ease;
  margin-left: auto;
  font-family: -apple-system, system-ui, sans-serif !important;
  background-color: ${({ appearance }) =>
    APPEARANCES[appearance]?.backgroundColor};
  color: ${({ appearance }) => APPEARANCES[appearance]?.color};

  &:hover {
    background-color: ${({ appearance }) =>
      APPEARANCES[appearance]?.backgroundHoverColor};
  }
  &:focus {
    box-shadow: ${({ appearance }) =>
      `${APPEARANCES[appearance]?.shadowColor} 0px 0px 0px 2px`};
  }
  &:active {
    background-color: ${({ appearance }) =>
      APPEARANCES[appearance]?.backgroundActiveColor};
  }
`;
